<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí marking ticket</h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep">
        <b-tab title="Danh sách" active title-link-class="btn btn-light-info">
          <MarkingTicket></MarkingTicket>
        </b-tab>
        <b-tab title="Thống kê" title-link-class="btn btn-light-info">
          <Statistical></Statistical>
        </b-tab>
        <b-tab
            title="Marker Monthly Report"
            title-link-class="btn btn-light-info"
        >
          <MarkerMonthlyReport/>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
import MarkerMonthlyReport from "./MarkerMonthlyReport";

export default {
  name: "TabMarkingTicket",
  components: {
    MarkerMonthlyReport,
    MarkingTicket: () => import("./MarkingTicket.vue"),
    Statistical: () => import("./Statistical.vue"),
  },
  data() {
    return {
      currentStep: 0,
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="css" scoped></style>
