<template>
  <div v-if="canAccess('statistical-ticket')">
    <loading :active.sync="is_call_api"></loading>
    <v-row>
      <v-col cols="6" class="d-flex align-items-center pb-0 pt-0">
        <div>
          <label class="text-body-1 font-weight-medium"
            >Khoảng thời gian :</label
          >
        </div>
        <div class="d-flex ml-2">
          <label class="text-body-1 font-weight-medium mb-6 pt-4">Từ:</label>
          <date-picker
            style=""
            class="ml-2 mt-3"
            v-model="date_start_input"
            value-type="format"
            format="YYYY-MM-DD"
          />
        </div>
        <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
        <div class="d-flex">
          <label class="text-body-1 font-weight-medium mb-6 pt-4">Đến:</label>
          <date-picker
            style=""
            class="ml-2 mt-3"
            v-model="date_end_input"
            value-type="format"
            format="YYYY-MM-DD"
          />
        </div>
      </v-col>

      <v-col cols="12" class="p-0 m-0"></v-col>

      <v-col cols="6" class="d-flex align-items-center pb-0 pt-3">
        <p class="pt-0 text-body-1 font-weight-medium">Markers:</p>
        <div style="width: 70.5%">
          <v-checkbox
            dense
            style="width: 100%"
            class="m-0 p-0 pl-4"
            v-model="is_all_marker"
            label="Chọn tất cả marker"
          ></v-checkbox>
          <v-autocomplete
            v-if="!is_all_marker"
            class="pl-4 mt-1"
            outlined
            color="blue-grey lighten-2"
            small-chips
            multiple
            deletable-chips
            :items="list_marker"
            item-text="name"
            item-value="id"
            v-model="marker_ids_input"
          ></v-autocomplete>
        </div>
      </v-col>

      <v-col cols="12" class="p-0 m-0"></v-col>

      <v-col cols="6" class="pb-0 pt-0 text-center">
        <v-btn
          class="ml-2"
          color="light-blue"
          outlined
          @click="getLinkReport()"
        >
          <v-icon class="mb-1" small left>mdi-export-variant</v-icon> GET LINK
        </v-btn>
        <span class="ml-1 text-body-1">(sẽ mất chút thời gian)</span>
      </v-col>

      <v-col cols="12" class="p-0 m-0"></v-col>

      <v-col cols="6" class="d-flex align-items-center pb-0 pt-3">
        <p class="pt-0 text-body-1 font-weight-medium">
          Dữ liệu báo cáo:
          <a
            v-if="url_download"
            class="text-decoration-underline light-blue--text text--lighten-1"
            target="_blank"
            :href="url_download"
            >Link download</a
          >
          <span v-if="!url_download" class="grey--text text--darken-1"
            >(Chọn khoảng ngày để lấy link download)</span
          >
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MarkerMonthlyReport",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      date_start_input: null,
      date_end_input: null,
      url_download: null,
      list_marker: [],
      marker_ids_input: [],
      is_all_marker: true,
    };
  },
  created() {
    this.getListMarker();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    getListMarker() {
      let vm = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          vm.list_marker = response.data;
        }
      });
    },
    validateForm() {
      let flat = true;
      if (!this.is_all_marker && this.marker_ids_input.length <= 0) {
        this.$toasted.error("Hãy chọn marker !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }

      return flat;
    },
    async getLinkReport() {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }

      let vm = this;
      let url = "prep-app/marking-ticket/marker-monthly-report?";
      if (this.date_start_input) {
        url += "&from_date=" + this.date_start_input;
      }
      if (this.date_end_input) {
        url += "&to_date=" + this.date_end_input;
      }
      if (!this.is_all_marker && this.marker_ids_input.length > 0) {
        url += "&marker_ids=" + vm.marker_ids_input;
      }

      // console.log(url)
      this.is_call_api = true;
      await ApiService.get(url)
        .then(function (res) {
          if (res.status === 200) {
            vm.url_download = res.data;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
